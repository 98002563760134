<template>
  <v-app>
    <v-container>
      <v-alert v-model="success" type="success" dismissible>
        {{dialogText}}
      </v-alert>
      <v-alert v-model="error" type="error" dismissible>
        {{dialogText}}
      </v-alert>
      <v-card>
        <v-card-title class="indigo white--text" style="font-size:30px">
          おしらせ一覧
        </v-card-title>
        <v-data-table
          :headers="informationHeaders"
          :items="informationItems"
          class="title"
          :sort-by.sync="sortItem"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:[`item.read_status`]="{ item }">
            <v-icon v-if="!item.read_status" color="red">mdi-alert-circle</v-icon>
          </template>
          <template v-slot:[`item.information_path`]="{ item }">
            <v-btn text @click="getInforamtionFile(item)" color="primary">
              PDFを開く
              <v-icon small>mdi-open-in-new</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
import { Storage } from 'aws-amplify';

  export default {
    data: () => ({                  
      apigwurl: process.env.VUE_APP_APIURL,

      informationHeaders:[
          { text:'', sortable: false, value:'read_status' },
          { text:'タイトル', sortable: true, value:'information_title' },
          { text:'公開日時', sortable: true, value:'start_time' },
          { text:'', sortable: false, value:'information_path' },
      ],
      informationItems:[],
      sortItem:'start_time',
      sortDesc:true,
      dialogText:null,
      success:false,
      error:false,
    }),
    methods: {
      //お知らせ情報取得
      getInformation:function(){
        var apiurl = this.apigwurl + "/user_information/" + this.loginid +"/?page=information";
        var unreadList = this.$route.params.id;
        this.$axios.get(apiurl, null).then(res => {
          this.informationItems = new Array();
          this.informationItems = res.data;
          for(var i in unreadList){
            var index = this.informationItems.findIndex(item => item.information_id == unreadList[i])
            if(index > -1){
              this.informationItems[index].read_status = false;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.dialogText = 'おしらせ情報の取得に失敗しました。';
          this.success = false;
          this.error = true;
          this.returnTop('auto');
        });
      },
      getInforamtionFile: function(item){
        Storage.get(item.information_path)
        .then(result => {
          window.open(result);
        })
        .catch((err) => {
          console.log(err);
          this.dialogText = item.information_title + 'の取得に失敗しました。';
          this.success = false;
          this.error = true;
          this.returnTop('auto');
        });
      },
      returnTop(action) {
        window.scrollTo({
          top: 0,
          behavior: action
        })
      },
    },
    beforeMount() {
      this.loginid = this.$store.state.user.username
      this.getInformation();
    }
  }
 </script>